'use client';
import { Button, Flex, Text, Title } from '@mantine/core';
import Link from 'next/link';

export default function Error({ error }: { error: Error }) {
  return (
    <Flex
      align={'center'}
      justify={'center'}
      mih={'100%'}
      direction={'column'}
      gap={'md'}
      w={'100%'}
      h={'100%'}
    >
      <Title>Not Found</Title>
      <Text size={'sm'} color={'dimmed'} italic>
        The page you&#39;re looking for doesn&#39;t seem to exist...
      </Text>
      <Link href={'/'} passHref>
        <Button component={'a'}>Return Home</Button>
      </Link>
    </Flex>
  );
}
